import { JSONContent } from "@tiptap/react";
import React from "react";

export type TRenderNode = ({
  children,
}: {
  children?: React.ReactNode;
}) => React.JSX.Element;

export type TRenderNodeHeading = ({
  children,
  level,
}: {
  children?: React.ReactNode;
  level: number;
}) => React.JSX.Element;

export interface IImageAttrs {
  src: string;
  width: number;
  height: number;
}

export type TRenderLink = ({
  children,
  attrs,
}: {
  children: React.ReactNode;
  attrs: ILinkAttrs;
}) => React.JSX.Element;

export interface ILinkAttrs {
  href: string;
}

export type TRenderImage = ({
  attrs,
}: {
  attrs: IImageAttrs;
}) => React.JSX.Element;

export interface IYoutubeAttrs {
  src: string;
  width: number;
  height: number;
}

export type TRenderYoutube = ({
  attrs,
}: {
  attrs: IYoutubeAttrs;
}) => React.JSX.Element;

export type TRenderTypeMap = {
  content: {
    paragraph: TRenderNode;
    heading: TRenderNodeHeading;
    text: TRenderNode;
    hardBreak: TRenderNode;
    orderedList: TRenderNode;
    bulletList: TRenderNode;
    listItem: TRenderNode;
    imageItem: TRenderImage;
    youtubeItem: TRenderYoutube;
  };
  marks: {
    bold: TRenderNode;
    italic: TRenderNode;
    underline: TRenderNode;
    strike: TRenderNode;
    none: TRenderNode;
    link: TRenderLink;
  };
};

function RenderTextNode({
  content,
  renderTypeMap,
}: {
  content: JSONContent;
  renderTypeMap: TRenderTypeMap;
}) {
  let ret = <>{content.text}</>;
  for (const mark of content.marks || []) {
    if (mark.type === "bold") {
      ret = <renderTypeMap.marks.bold>{ret}</renderTypeMap.marks.bold>;
    }
    if (mark.type === "italic") {
      ret = <renderTypeMap.marks.italic>{ret}</renderTypeMap.marks.italic>;
    }
    if (mark.type === "underline") {
      ret = (
        <renderTypeMap.marks.underline>{ret}</renderTypeMap.marks.underline>
      );
    }
    if (mark.type === "strike") {
      ret = <renderTypeMap.marks.strike>{ret}</renderTypeMap.marks.strike>;
    }
    if (mark.type === "link" && mark.attrs?.href) {
      ret = (
        <renderTypeMap.marks.link attrs={mark.attrs as ILinkAttrs}>
          {ret}
        </renderTypeMap.marks.link>
      );
    }
  }

  return <renderTypeMap.marks.none>{ret}</renderTypeMap.marks.none>;
}

export function RenderTiptapContent({
  content,
  renderTypeMap,
}: {
  content: JSONContent;
  renderTypeMap: TRenderTypeMap;
}) {
  if (content.type === "doc") {
    return (
      <>
        {content.content?.map((node, index) => (
          <RenderTiptapContent
            key={index}
            content={node}
            renderTypeMap={renderTypeMap}
          />
        ))}
      </>
    );
  }
  if (content.type === "paragraph") {
    return (
      <renderTypeMap.content.paragraph>
        {content.content?.map((node, index) => (
          <RenderTiptapContent
            key={index}
            content={node}
            renderTypeMap={renderTypeMap}
          />
        ))}
      </renderTypeMap.content.paragraph>
    );
  }
  if (content.type === "heading" && content.attrs?.level) {
    return (
      <renderTypeMap.content.heading level={content.attrs.level}>
        {content.content?.map((node, index) => (
          <RenderTiptapContent
            key={index}
            content={node}
            renderTypeMap={renderTypeMap}
          />
        ))}
      </renderTypeMap.content.heading>
    );
  }

  if (content.type === "text") {
    return <RenderTextNode content={content} renderTypeMap={renderTypeMap} />;
  }
  if (content.type === "hardBreak") {
    return <renderTypeMap.content.hardBreak />;
  }
  if (content.type === "orderedList") {
    return (
      <renderTypeMap.content.orderedList>
        {content.content?.map((node, index) => (
          <RenderTiptapContent
            key={index}
            content={node}
            renderTypeMap={renderTypeMap}
          />
        ))}
      </renderTypeMap.content.orderedList>
    );
  }
  if (content.type === "bulletList") {
    return (
      <renderTypeMap.content.bulletList>
        {content.content?.map((node, index) => (
          <RenderTiptapContent
            key={index}
            content={node}
            renderTypeMap={renderTypeMap}
          />
        ))}
      </renderTypeMap.content.bulletList>
    );
  }
  if (content.type === "listItem") {
    return (
      <renderTypeMap.content.listItem>
        {content.content?.map((node, index) => (
          <RenderTiptapContent
            key={index}
            content={node}
            renderTypeMap={renderTypeMap}
          />
        ))}
      </renderTypeMap.content.listItem>
    );
  }
  if (content.type === "image") {
    return (
      <renderTypeMap.content.imageItem attrs={content.attrs as IImageAttrs} />
    );
  }
  if (content.type === "youtube") {
    return (
      <renderTypeMap.content.youtubeItem
        attrs={content.attrs as IYoutubeAttrs}
      />
    );
  }
  return <></>;
}
